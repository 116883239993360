
import SmartStatusRegionfilterMenu from "../components/SmartStatusRegionMenu.vue";
import { defineComponent, ref } from "vue";
import constants from "@/styles/constants";
import { useRegionStore } from "@/store/regions";

export default defineComponent({
  name: "SmartStatusRegionfilter",
  components: { SmartStatusRegionfilterMenu },

  setup() {
    // #region data
    const nzRegion = {
      unitCode: "",
      parentUnitCode: null,
      unitName: "New Zealand",
      unitLevel: 1,
      unitLevelName: "Region",
    };
    const regionStore = useRegionStore();
    const isOpen = ref(constants.windowIsOpen);

    // #endregion

    // # region methods

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    // #endregion

    return {
      nzRegion,
      regionStore,
      isOpen,
      toggleOpen,
    };
  },
});
