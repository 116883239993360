import { useRegionStore } from "@/store/regions";
export interface IApplianceDto {
  callSignCode: string;
  kCodeIdentifier?: keyof typeof KCODES;
  kCodeStatus?: string;
  updatedTimestamp: string; // TODO change to date
  organisationUnitCodeLevelOne: string | null;
  organisationUnitCodeLevelTwo: string | null;
  stationIdentifier: string;
  redFleetIndicator: boolean;
  commonEventIdentifier?: number | null;
  cadNumber?: string;
  eventTypeCode?: string;
  eventTypeDescription?: string;
}

export interface IApplianceVm extends IApplianceDto {
  kCodeOrder?: KCODES;
  stationName: string;
}

// prettier-ignore
export const KCODES = {
  "K55": 1,
  "K66": 2,
  "K77": 3,
  "K88": 4,
  "K99": 5,
  "K1": 6,
  "K2": 7,
  "K3": 8,
  "K4": 9,
  "K5": 10,
  "K6": 11,
  "K7": 13,
  "K9": 13,
  "K0": 14,

} as const;
export type KCODES = typeof KCODES[keyof typeof KCODES];

export function toApplianceVm(appliance: IApplianceDto): IApplianceVm {
  const regionStore = useRegionStore();
  return {
    ...appliance,
    kCodeOrder: KCODES[appliance.kCodeIdentifier ?? "K1"],
    // TODO do we want to change this to a dictionary lookup of all station names?
    stationName:
      regionStore.getStations.find(
        (a) => appliance.stationIdentifier === a.unitCode
      )?.unitName ?? "",
  };
}
