import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SmartStatus from "../views/SmartStatus.vue";
import Home from "../views/Home.vue";
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/smartstatus",
    name: "SmartStatus",
    component: SmartStatus,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/icad",
    name: "ICAD",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "icad" */ "../views/ICAD.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Report.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

registerGuard(router);

export default router;
