
import { defineComponent, ref } from "vue";
import SmartStatusFilter, { FilterCountType } from "./SmartStatusFilter.vue";
import { useApplianceStore } from "@/store/appliances";

export default defineComponent({
  name: "SmartStatusAppliancesFilter",
  components: { SmartStatusFilter },

  setup() {
    const applianceStore = useApplianceStore();
    // #region data
    const filterCategories = [
      {
        categoryTitle: "CallSign Status",
        filters: [
          {
            text: "On Station",
            filterCountGet: "getOnStationCount" as FilterCountType,
            showNumber: true,
            numberColour: "black",
            initialValue: applianceStore.filters.status.onStation,
            handleToggle: (): void => applianceStore.toggleOnStationFilter(),
          },
          {
            text: "Enroute",
            filterCountGet: "getEnrouteCount" as FilterCountType,
            showNumber: true,
            numberColour: "red",
            initialValue: applianceStore.filters.status.enroute,
            handleToggle: (): void => applianceStore.toggleEnrouteFilter(),
          },
          {
            text: "On Scene",
            filterCountGet: "getOnSceneCount" as FilterCountType,
            showNumber: true,
            numberColour: "red",
            initialValue: applianceStore.filters.status.onScene,
            handleToggle: (): void => applianceStore.toggleOnSceneFilter(),
          },
          {
            text: "On Road",
            filterCountGet: "getOnRoadCount" as FilterCountType,
            showNumber: true,
            numberColour: "green",
            initialValue: applianceStore.filters.status.onRoad,
            handleToggle: (): void => applianceStore.toggleOnRoadFilter(),
          },
          {
            text: "Unavailable",
            filterCountGet: "getUnavailableCount" as FilterCountType,
            showNumber: true,
            numberColour: "grey",
            initialValue: applianceStore.filters.status.unavailable,
            handleToggle: (): void => applianceStore.toggleUnavailableFilter(),
          },
        ],
      },
      {
        categoryTitle: "Fleet",
        filters: [
          {
            text: "Red fleet",
            filterCountGet: "getOnStationCount" as FilterCountType,
            showNumber: false,
            numberColour: "",
            initialValue: applianceStore.filters.redFleet,
            handleToggle: (): void => applianceStore.toggleRedFleetFilter(),
          },
          {
            text: "White fleet",
            filterCountGet: "getOnStationCount" as FilterCountType,
            showNumber: false,
            numberColour: "",
            initialValue: applianceStore.filters.whiteFleet,
            handleToggle: (): void => applianceStore.toggleWhiteFleetFilter(),
          },
        ],
      },
    ];
    // TODO open or closed by default??
    const isOpen = ref(true);

    // #endregion

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      toggleOpen,
      applianceStore,
      filterCategories,
    };
  },
});
