
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "GenericTableHeaderButton",
  emits: ["fieldClicked"],
  props: {
    label: { type: String, required: true },
    className: String,
    sortable: Boolean,
    smallCols: { type: Number, default: 0 }, // TODO check default
    largeCols: { type: Number, default: 0 }, // TODO check default
  },

  setup(props, context) {
    // #region data

    const colClass = computed(
      () =>
        `col-${props.smallCols.toString()} col-md-${props.largeCols.toString()}`
    );

    // #endregion

    // # region methods

    const emitSortBy = (fieldClicked: string) => {
      context.emit("fieldClicked", fieldClicked);
    };

    // #endregion

    return {
      colClass,
      emitSortBy,
    };
  },
});
