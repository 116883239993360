import { defineStore } from "pinia";

export const useLoadingStateStore = defineStore("loadingState", {
  state: () => ({
    appliancesLoading: false,
    filteringActive: false,
    regionsLoading: false,
  }),
  getters: {
    getApplianceTableLoading(state): boolean {
      return state.appliancesLoading || state.filteringActive;
    },
  },
  actions: {},
});
