
import { defineComponent } from "vue";
import Tooltip from "./Tooltip.vue";

export default defineComponent({
  name: "IncidentPopover",
  components: { Tooltip },
  props: {
    heading: String,
    units: String,
    tag: String,
    attending: String,
    targetId: String,
  },
});
