
import { computed, defineComponent, PropType } from "vue";
import { useApplianceStore } from "@/store/appliances";
import { UnitArea, useRegionStore } from "@/store/regions";
import { useLoadingStateStore } from "@/store/loadingState";

export default defineComponent({
  name: "SmartStatusRegionfilterMenu",
  components: {},
  props: {
    region: { type: Object as PropType<UnitArea> },
  },

  setup(props) {
    // TODO observe how isOpen = true affects mobile view
    const applianceStore = useApplianceStore();
    const regionStore = useRegionStore();

    const isOpen = computed(
      () => applianceStore.filters.region.unitCode === props.region?.unitCode
    );
    const selectedDistrict = computed(
      () => applianceStore.filters.district.unitCode
    );
    const districts = regionStore.getDistricts.filter(
      (region) => region.parentUnitCode === props.region?.unitCode
    );
    const onSelectRegion = () => {
      applianceStore.updateRegionFilter(props.region as UnitArea);
    };
    const onSelectDistrict = (district: UnitArea) => {
      applianceStore.updateDistrictFilter(district);
    };

    return {
      isOpen,
      selectedDistrict,
      districts,
      onSelectRegion,
      onSelectDistrict,
    };
  },
});
