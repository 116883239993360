const headerData = {
  smartStatusButtonLabel: "Smart Status",
  icadButtonLabel: "ICAD Reporting",

  smartMapLinkLabel: "Smart Map",
  smartMapLink: "www.google.com",
  portalLinkLabel: "FENZ Portal",
  portalLink: "www.google.com",
};

export default headerData;
