
import { defineComponent } from "vue";
import { FingerprintSpinner } from "epic-spinners";
import { useLoadingStateStore } from "@/store/loadingState";

export default defineComponent({
  name: "LoadingSpinner",
  components: {
    FingerprintSpinner,
  },
  props: {
    extraClass: String,
  },

  setup() {
    const loadingStateStore = useLoadingStateStore();
    return {
      loadingStateStore,
    };
  },
});
