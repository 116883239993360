
import SmartStatusRegionfilter from "../components/SmartStatusRegionfilter.vue";
import SmartStatusAppliances from "../components/SmartStatusAppliances.vue";
import { defineComponent } from "vue";
import { useApplianceStore } from "@/store/appliances";
import { useRegionStore } from "@/store/regions";

export default defineComponent({
  name: "SmartStatus",
  components: {
    SmartStatusRegionfilter,
    SmartStatusAppliances,
  },

  setup() {
    // #region data
    // TODO figure out where the data should be loaded in...
    const applianceStore = useApplianceStore();
    const regionStore = useRegionStore();

    applianceStore.loadAppliancesFromApi();
    regionStore.loadRegionsFromApi();
    // #endregion
  },
});
