
import NotificationTag from "../components/NotificationTag.vue";
import IncidentIcon from "../components/IncidentIcon.vue";
import IncidentPopover from "../components/IncidentPopover.vue";
import LocationPopover from "./LocationPopover.vue";
import "tippy.js/dist/tippy.css";
import { computed, defineComponent, ref, ComponentPublicInstance } from "vue";

export default defineComponent({
  name: "SmartStatusAppliancesTableAppliance",
  components: {
    NotificationTag,
    IncidentIcon,
    IncidentPopover,
    LocationPopover,
  },
  props: {
    callSignCode: String,
    kCodeIdentifier: String,
    kCodeStatus: { type: String, default: "" },
    updatedTimestamp: String,
    eventTypeDescription: String,
    cadNumber: String,
    // TODO add data to link props, populated from ICAD store...
    mapLink: { type: String, default: "" },
    reportLink: { type: String, default: "" },
    eventTypeCode: String,
    // Don't like this approach but need to stop focusing on this too much for now
    // TODO use Bootstrap to add table-stripe rather than doing it manually
    oddKey: Boolean,
  },

  setup(props) {
    // #region data

    const popupRef = ref<ComponentPublicInstance | null>(null);

    // TODO just use values.toLower for the classes (change class names)?
    const topClass = computed(() => {
      if (props.kCodeStatus.toLowerCase().includes("unavailable")) {
        return "unavailable";
      } else if (props.kCodeStatus.toLowerCase().includes("enroute")) {
        return "enroute";
      } else if (props.kCodeStatus.toLowerCase().includes("scene")) {
        return "on-scene";
      } else if (props.kCodeStatus.toLowerCase().includes("road")) {
        return "on-road";
      } else if (props.kCodeStatus.toLowerCase().includes("station")) {
        return "on-station";
      } else {
        return "";
      }
    });

    const showLocationData = computed(() => {
      if (props.kCodeIdentifier === "K3" || props.kCodeIdentifier === "K9") {
        return true;
      } else {
        return false;
      }
    });

    // #endregion

    return {
      topClass,
      popupRef,
      showLocationData,
    };
  },
});
