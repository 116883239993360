
import { useIsAuthenticated } from "@/auth/useIsAuthenticated";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    const isAuthenticated = useIsAuthenticated();
    return { isAuthenticated };
  },
});
