
import GenericTableHeaderButton from "../components/GenericTableHeaderButton.vue";
import SmartStatusAppliancesTableAppliance from "../components/SmartStatusAppliancesTableAppliance.vue";
import { defineComponent } from "vue";
import { useApplianceStore } from "@/store/appliances";
import LoadingSpinner from "./LoadingSpinner.vue";

export default defineComponent({
  name: "SmartStatusAppliancesTable",
  components: {
    GenericTableHeaderButton,
    SmartStatusAppliancesTableAppliance,
    LoadingSpinner,
  },
  props: {
    selectedRegion: String,
  },

  setup() {
    // #region data

    const applianceStore = useApplianceStore();

    // #endregion

    // #region methods

    const sortBy = (fieldClicked: string) => {
      applianceStore.updateFieldToSort(fieldClicked);
    };
    return { applianceStore, sortBy };

    // #endregion
  },
});
