import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "appliance-popover__top" }
const _hoisted_2 = { class: "appliance-popover__heading" }
const _hoisted_3 = { class: "appliance-popover__units" }
const _hoisted_4 = { class: "appliance-popover__bot" }
const _hoisted_5 = { class: "appliance-popover__tag" }
const _hoisted_6 = { class: "appliance-popover__attending" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createBlock(_component_Tooltip, {
    class: "appliance-popover",
    targetId: _ctx.targetId
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.heading), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.units), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.tag), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.attending), 1)
      ])
    ]),
    _: 1
  }, 8, ["targetId"]))
}