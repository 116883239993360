import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "appliances-table d-flex flex-column" }
const _hoisted_2 = { class: "right-align" }
const _hoisted_3 = { class: "appliances-table__header d-none flex-row d-md-flex" }
const _hoisted_4 = { class: "appliances-table__appliances d-flex flex-column" }
const _hoisted_5 = { class: "appliances-table__appliance-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericTableHeaderButton = _resolveComponent("GenericTableHeaderButton")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SmartStatusAppliancesTableAppliance = _resolveComponent("SmartStatusAppliancesTableAppliance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'clear-sort',
        label: "CLEAR SORT",
        widthPercent: 15,
        sortable: false,
        smallCols: 1,
        largeCols: 1,
        onFieldClicked: _ctx.sortBy
      }, null, 8, ["onFieldClicked"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'station',
        label: "Station",
        widthPercent: 15,
        sortable: true,
        smallCols: 2,
        largeCols: 2,
        onFieldClicked: _ctx.sortBy
      }, null, 8, ["onFieldClicked"]),
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'status',
        label: "Status",
        widthPercent: 15,
        sortable: true,
        smallCols: 2,
        largeCols: 2,
        onFieldClicked: _ctx.sortBy
      }, null, 8, ["onFieldClicked"]),
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'updated',
        label: "Last updated",
        widthPercent: 15,
        sortable: true,
        smallCols: 2,
        largeCols: 2,
        onFieldClicked: _ctx.sortBy
      }, null, 8, ["onFieldClicked"]),
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'incident-type',
        label: "Incident type",
        widthPercent: 20,
        sortable: true,
        smallCols: 2,
        largeCols: 2,
        onFieldClicked: _ctx.sortBy
      }, null, 8, ["onFieldClicked"]),
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'icad-number',
        label: "ICAD Number",
        widthPercent: 15,
        sortable: true,
        smallCols: 2,
        largeCols: 2,
        onFieldClicked: _ctx.sortBy
      }, null, 8, ["onFieldClicked"]),
      _createVNode(_component_GenericTableHeaderButton, {
        className: 'actions',
        label: "Actions",
        widthPercent: 20,
        sortable: false,
        smallCols: 2,
        largeCols: 2
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_LoadingSpinner, { extraClass: "appliances-table__appliance-spinner" }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applianceStore.appliancesFilteredAndSorted, (groupedAppliances) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "appliances-table__appliance-container d-flex flex-column",
          key: groupedAppliances.key
        }, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(groupedAppliances.stationName), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedAppliances.appliances, (unit, j) => {
            return (_openBlock(), _createBlock(_component_SmartStatusAppliancesTableAppliance, {
              key: unit.callSignCode,
              callSignCode: unit.callSignCode,
              kCodeIdentifier: unit.kCodeIdentifier,
              kCodeStatus: unit.kCodeStatus,
              updatedTimestamp: unit.updatedTimestamp,
              eventTypeDescription: unit.eventTypeDescription,
              cadNumber: unit.cadNumber,
              eventTypeCode: unit.eventTypeCode,
              oddKey: j % 2 === 0
            }, null, 8, ["callSignCode", "kCodeIdentifier", "kCodeStatus", "updatedTimestamp", "eventTypeDescription", "cadNumber", "eventTypeCode", "oddKey"]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}