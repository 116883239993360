
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "IncidentIcon",
  components: {},
  props: {
    type: { type: String, default: "" },
  },

  setup(props) {
    // #region data

    const imgClass = computed(() => {
      // TODO potential options to change this:
      // 1. change from if/else/switch into a dictionary/lookup which is used externally
      // to an instance of the component.
      // 2. if we can verify that what we are returning here is the exact same as the prop,
      // we can get rid of it and just validate prop input

      // Strictly speaking you dont need this right now, but only because I know what the mock data is
      // Anything on the left/case side below can be adapted for the data feed
      switch (props.type.toLowerCase()) {
        case "adv":
          return "adv";
        case "airh":
          return "airh";
        case "airl":
          return "airl";
        case "chemcall":
          return "chemcall";
        case "firetest":
          return "firetest";
        case "haz":
          return "haz";
        case "med":
          return "med";
        case "medfr":
          return "medfr";
        case "minor-event":
          return "minor-event";
        case "nat":
          return "nat";
        case "nat1":
          return "nat1";
        case "nat2":
          return "nat2";
        case "nat3":
          return "nat3";
        case "nema":
          return "nema";
        case "not":
          return "not";
        case "nur":
          return "nur";
        case "resc":
          return "rescue";
        case "ship":
          return "ship";
        case "sprinkler":
          return "sprinkler";
        case "stncall":
          return "stncall";
        case "stru":
          return "stru";
        case "tevac":
          return "tevac";
        case "usar":
          return "usar";
        case "veg":
          return "veg";
        default:
          return "minor-event";
      }
    });
    // #endregion

    return {
      imgClass,
    };
  },
});
