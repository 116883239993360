import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["generic-table-header-button d-flex flex-row justify-content-start align-items-center", [_ctx.className, _ctx.colClass]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitSortBy(_ctx.label)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    (_ctx.sortable)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: "sort",
          class: "generic-table-header-button__sort-icon"
        }))
      : _createCommentVNode("", true)
  ], 2))
}