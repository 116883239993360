import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "appliance-popover__heading" }
const _hoisted_2 = { class: "appliance-popover__units" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createBlock(_component_Tooltip, {
    class: "appliance-popover",
    targetId: _ctx.$props.targetId
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.heading), 1),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.location), 1)
      ])
    ]),
    _: 1
  }, 8, ["targetId"]))
}