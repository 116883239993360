
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "NotificationTag",
  props: {
    value: { type: String, default: "" },
    extraClass: String,
    colorType: String,
  },

  setup(props) {
    // #region data

    const statusClass = computed(() => {
      // TODO add some props validation so only certain types are accepted
      // (means we can take out the .toLowerCase stuff)
      // Could also move it out to Enumify so it acts like an enum?
      const colorType = props.colorType?.toLowerCase();
      if (
        colorType === "notification" ||
        colorType === "on-road" ||
        colorType === "green"
      ) {
        return "green";
      } else if (
        colorType === "on-scene" ||
        colorType === "enroute" ||
        colorType === "red"
      ) {
        return "red";
      } else if (colorType === "on-station" || colorType === "black") {
        return "black";
      } else {
        return "grey";
      }
    });

    const showTag = computed(
      () => parseInt(props.value) > 0 || props.value !== ""
    );

    // #endregion

    return {
      statusClass,
      showTag,
    };
  },
});
