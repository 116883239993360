import { defineStore } from "pinia";
import _ from "lodash";
import {
  toApplianceVm,
  IApplianceVm,
  IApplianceDto,
} from "@/models/applianceVm";
import { useStorage } from "@vueuse/core";
import { UnitArea } from "./regions";
import { IIncrClient, IncrClient } from "@/data/incrApiClient";
import { useLoadingStateStore } from "./loadingState";
import { msalInstance } from "@/authConfig";
const SORTBY = {
  ASC: "asc",
  DESC: "desc",
} as const;

type SORTBY = typeof SORTBY[keyof typeof SORTBY];

const SORTFIELDS = {
  ICAD: "ICAD Number",
  INCIDENT: "Incident type",
  UPDATED: "Last updated",
  KCODE: "Status",
  STATION: "Station",
} as const;

export type SORTFIELDS = typeof SORTFIELDS[keyof typeof SORTFIELDS];

interface IStatusFilters {
  onStation: boolean;
  enroute: boolean;
  onScene: boolean;
  onRoad: boolean;
  unavailable: boolean;
}
interface IApplianceFilters {
  region: UnitArea;
  district: UnitArea;
  status: IStatusFilters;
  redFleet: boolean;
  whiteFleet: boolean;
}

const filterApplianceByArea = (
  appliance: IApplianceDto,
  region: UnitArea,
  district: UnitArea
): boolean => {
  return (
    (!region.unitCode ||
      appliance.organisationUnitCodeLevelOne === region.unitCode) &&
    (!district.unitCode ||
      appliance.organisationUnitCodeLevelTwo === district.unitCode)
  );
};

const filterApplianceByFleet = (
  appliance: IApplianceDto,
  redFleet: boolean,
  whiteFleet: boolean
): boolean => {
  return (
    (redFleet && appliance.redFleetIndicator) ||
    (whiteFleet && !appliance.redFleetIndicator)
  );
};

const filterApplianceByStatus = (
  appliance: IApplianceDto,
  filters: IStatusFilters
): boolean => {
  if (filters.onStation && applianceOnStation(appliance)) return true;
  if (filters.enroute && applianceEnroute(appliance)) return true;
  if (filters.onScene && applianceOnScene(appliance)) return true;
  if (filters.onRoad && applianceOnRoad(appliance)) return true;
  if (filters.unavailable && applianceUnavailable(appliance)) return true;
  return false;
};
const applianceOnStation = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K7" || appliance.kCodeIdentifier == "K9";
const applianceEnroute = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K1";
const applianceOnScene = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K2" ||
  appliance.kCodeIdentifier == "K5" ||
  appliance.kCodeIdentifier == "K55" ||
  appliance.kCodeIdentifier == "K66" ||
  appliance.kCodeIdentifier == "K77" ||
  appliance.kCodeIdentifier == "K88" ||
  appliance.kCodeIdentifier == "K99";
const applianceOnRoad = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K3" ||
  appliance.kCodeIdentifier == "K4" ||
  appliance.kCodeIdentifier == "K6";
const applianceUnavailable = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K0";

interface IGroupedAppliance {
  stationName: string;
  appliances: IApplianceVm[];
  key: string;
}
const groupApplianceBy = (
  appliances: IApplianceDto[],
  keyGenerator: (appliance: IApplianceVm) => string,
  sortByDirection: SORTBY,
  type: string
): IGroupedAppliance[] => {
  return _.chain(appliances)
    .map(toApplianceVm)
    .groupBy(keyGenerator)
    .map((value, key) => {
      const keys = key.split("|");
      let sortByValue: string | number | Date = keys[1];
      if (type === "number") {
        sortByValue = +keys[1];
      } else if (type === "date") {
        // TODO do we want to change how we do this?
        // This works, it's just janky. Saves parsing Date for each appliance.
        sortByValue = value[0].updatedTimestamp;
      }
      return {
        stationName: keys[0],
        sortBy: sortByValue,
        appliances: value,
        key: key,
      };
    })
    .orderBy(["sortBy", "stationName"], [sortByDirection, "asc"])
    .value();
};
export const useApplianceStore = defineStore("appliances", {
  state: () => ({
    appliances: [] as IApplianceDto[],
    filteredAppliancesByArea: [] as IApplianceDto[],
    filteredAppliances: [] as IApplianceDto[],
    sortedField: "",
    sortDirection: SORTBY.ASC as SORTBY,
    filters: useStorage("appliance-filters", {
      region: {} as UnitArea,
      district: {} as UnitArea,
      status: {
        onStation: true,
        enroute: true,
        onScene: true,
        onRoad: true,
        unavailable: true,
      },
      redFleet: true,
      whiteFleet: true,
    } as IApplianceFilters),
  }),
  getters: {
    // Handles filter/sort per field
    appliancesFilteredAndSorted(state): IGroupedAppliance[] {
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.filteringActive = true;
      let fieldDataType = "";

      let keyGenerator = (a: IApplianceVm) => {
        return `${a.stationName}|${a.stationIdentifier}`;
      };

      if (state.sortedField == SORTFIELDS.ICAD) {
        keyGenerator = (a) =>
          `${a.stationName}|${a.cadNumber ?? "emptyCadNumber"}`;
      } else if (state.sortedField == SORTFIELDS.INCIDENT) {
        keyGenerator = (a) =>
          `${a.stationName}|${
            a.eventTypeDescription ?? "emptyEventTypeDescription"
          }`;
      } else if (state.sortedField == SORTFIELDS.UPDATED) {
        // TODO uncomment this once updatedTimestamp is a Date
        // fieldDataType = "date";
        keyGenerator = (a) => `${a.stationName}|${a.updatedTimestamp}`;
      } else if (state.sortedField == SORTFIELDS.KCODE) {
        fieldDataType = "number";
        keyGenerator = (a) => `${a.stationName}|${a.kCodeOrder}`;
      } else if (state.sortedField == SORTFIELDS.STATION) {
        keyGenerator = (a) => `${a.stationName}|${a.stationName}`;
      }

      const groupedAppliances = groupApplianceBy(
        state.filteredAppliances,
        keyGenerator,
        state.sortDirection,
        fieldDataType
      );
      loadingStateStore.filteringActive = false;
      return groupedAppliances;
    },

    getRegionFilterName(state): string {
      if (state.filters.district?.unitCode)
        return state.filters.district.unitName;
      return state.filters.region?.unitName;
    },

    getOnStationCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceOnStation(a)
      ).length;
      return count;
    },
    getEnrouteCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceEnroute(a)
      ).length;
      return count;
    },
    getOnSceneCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceOnScene(a)
      ).length;
      return count;
    },
    getOnRoadCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceOnRoad(a)
      ).length;
      return count;
    },
    getUnavailableCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceUnavailable(a)
      ).length;
      return count;
    },
    getAreaFilteredCount(): number {
      const count = this.filteredAppliancesByArea.length;
      return count;
    },
  },
  actions: {
    async loadAppliancesFromApi() {
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.appliancesLoading = true;
      const client: IIncrClient = new IncrClient();
      // TODO check which ID is actually needed here...
      // might need to filter account where account.username.contains "fireandemergency.nz"
      const accountId = await msalInstance.getAllAccounts()[0].localAccountId;
      await client
        .getAppliancesList(accountId)
        .then((appliances) => {
          this.appliances = appliances;
          this.applyFiltering();
        })
        .catch(() => (loadingStateStore.appliancesLoading = false));
    },

    async applyFiltering() {
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.appliancesLoading = true;
      setTimeout(() => {
        const areaFilteredAppliances = this.appliances.filter(
          (a) =>
            filterApplianceByArea(
              a,
              this.filters.region,
              this.filters.district
            ) &&
            filterApplianceByFleet(
              a,
              this.filters.redFleet,
              this.filters.whiteFleet
            )
        );
        this.filteredAppliancesByArea = areaFilteredAppliances;
        this.filteredAppliances = areaFilteredAppliances.filter((a) =>
          filterApplianceByStatus(a, this.filters.status)
        );
        loadingStateStore.appliancesLoading = false;
      }, 50);
    },

    async updateFieldToSort(field: string) {
      if (field != this.sortedField) {
        this.sortedField = field;
        this.sortDirection = SORTBY.ASC;
      } else if (this.sortDirection == "asc") {
        this.sortDirection = SORTBY.DESC;
      } else {
        this.sortedField = "";
        this.sortDirection = "asc";
      }
    },

    updateRegionFilter(region: UnitArea) {
      this.filters.region = region;
      this.filters.district = {} as UnitArea;
      this.applyFiltering();
    },

    updateDistrictFilter(district: UnitArea) {
      this.filters.district = district;
      this.applyFiltering();
    },

    toggleOnStationFilter() {
      this.filters.status.onStation = !this.filters.status.onStation;
      this.applyFiltering();
    },
    toggleEnrouteFilter() {
      this.filters.status.enroute = !this.filters.status.enroute;
      this.applyFiltering();
    },
    toggleOnSceneFilter() {
      this.filters.status.onScene = !this.filters.status.onScene;
      this.applyFiltering();
    },
    toggleOnRoadFilter() {
      this.filters.status.onRoad = !this.filters.status.onRoad;
      this.applyFiltering();
    },
    toggleUnavailableFilter() {
      this.filters.status.unavailable = !this.filters.status.unavailable;
      this.applyFiltering();
    },

    toggleRedFleetFilter() {
      this.filters.redFleet = !this.filters.redFleet;
      this.applyFiltering();
    },

    toggleWhiteFleetFilter() {
      this.filters.whiteFleet = !this.filters.whiteFleet;
      this.applyFiltering();
    },
  },
});
