
import { useMsal } from "@/auth/useMsal";
import { defineComponent, computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import headerData from "../data/header-data";
import reportData from "../data/report-data";
import { loginRequest } from "../authConfig";
import { useIsAuthenticated } from "@/auth/useIsAuthenticated";

export default defineComponent({
  name: "Header",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const data = headerData;
    const isMenuOpen = ref(false);

    const onClick = (newTab: string): void => {
      isMenuOpen.value = false;
      router.push({ name: newTab });
    };
    const toggleMenu = (): void => {
      isMenuOpen.value = !isMenuOpen.value;
    };
    const handleCloseReport = (): void => {
      router.push({ name: "ICAD" });
    };
    const loginRedirect = () => {
      instance.loginRedirect(loginRequest);
    };
    const logoutRedirect = () => {
      instance.logoutRedirect();
    };

    const reportHeading = computed<string>(
      () => `${reportData.heading}: ${reportData.reportNumber}`
    );
    const currentRoute = computed(() => route.name);
    const showReportHeader = computed(() => route.name === "Report");

    return {
      data,
      isMenuOpen,
      reportData,
      currentRoute,
      showReportHeader,
      isAuthenticated,
      onClick,
      toggleMenu,
      handleCloseReport,
      reportHeading,
      loginRedirect,
      logoutRedirect,
    };
  },
});
