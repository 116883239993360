import { IIncrClient, IncrClient } from "@/data/incrApiClient";
import { defineStore } from "pinia";

export interface UnitArea {
  unitCode: string;
  parentUnitCode: string | null;
  unitName: string;
  unitLevel: number;
  unitLevelName: string;
  additionalAttribute?: string;
  additionalAttributeMeaning?: string;
}

export const useRegionStore = defineStore("regions", {
  state: () => ({
    units: [] as UnitArea[],
  }),
  getters: {
    getRegions(state): UnitArea[] {
      return state.units.filter((u) => u.unitLevel == 1);
    },
    getDistricts(state): UnitArea[] {
      return state.units.filter((u) => u.unitLevel == 2);
    },
    getStations(state): UnitArea[] {
      return state.units.filter((u) => u.unitLevel == 3);
    },
  },
  actions: {
    async loadRegionsFromApi() {
      const client: IIncrClient = new IncrClient();
      const units = await client.getUnits();
      this.units = units;
    },
  },
});
