
import tippy from "tippy.js";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "tooltip",
  props: { class: String, targetId: String },
  setup(props) {
    // #region data

    const root = ref<HTMLDivElement | null>(null);

    // #endregion

    // #region lifecycle
    const toolTipId = `#${props.targetId}`;

    onMounted(() => {
      if (root.value) {
        tippy(toolTipId, {
          content: root.value.innerHTML,
          allowHTML: true,
        });
      }
    });

    // #endregion
    return {
      root,
    };
  },
});
