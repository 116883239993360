const reportData = {
  // Head
  heading: "ICAD Report",
  closeLabel: "Close",
  printLabel: "Print",
  reportNumber: "F3012747",

  // Menu
  menuHeading: "Report menu",
  detailsLink: "Incident details",
  historyLink: "History",
  responsesLink: "Responses",
  coverMovesLink: "Cover Moves",
  notificationsLink: "Notifications",
  newNotifications: 1,
  messagesLink: "Messages",
  newMessages: 2,

  // Details
  type: "STRU",
  typeHeading: "Structure fire",
  typeCode: "STRU",
  typeLabel: "Alarm Activation - Commercial",
  startedLabel: "Incident started: ",
  startedData: "21 June 2021 00:18:22",
  address: "120 Henderson Rd, Woolwich, Hastings District",
  building: "Bostock Organic Coolstores Hastings",
  viewMapLabel: "View map",
  viewMapLink: "#",
  pfaLabel: "PFA",
  pfaText: "-",
  zoneLabel: "Zone",
  zoneText: "265602",
  stationLabel: "Station",
  stationText: "HASTINGS 2656",
  resultLabel: "Result",
  resultText: "7100: FALSE ALARM (STRU)",
  methodLabel: "Method",
  methodText: "111PMC",
  callerLabel: "1st Caller",
  callerText: "East Tech Security (06 873 0164)",
  nztmLabel: "NZTM",
  nztmText: "E 1926122   N 6507812",

  // History
  historyHeading: "History",
  statusHeading: "Status",
  timeHeading: "Time/Date",
  elapsedHeading: "Elapsed time",
  events: [
    {
      status: "Created",
      time: "21 June 2021 00:18:22",
      elapsed: "00:00:36",
    },
    {
      status: "Confirmed",
      time: "21 June 2021 00:18:22",
      elapsed: "00:01:36",
    },
    {
      status: "Alerted",
      time: "21 June 2021 00:18:22",
      elapsed: "00:05:36",
    },
    {
      status: "1st Response",
      time: "21 June 2021 00:18:22",
      elapsed: "00:06:36",
    },
    {
      status: "1st Arrival",
      time: "21 June 2021 00:18:22",
      elapsed: "00:06:36",
    },
    {
      status: "2nd Arrival",
      time: "21 June 2021 00:18:22",
      elapsed: "00:07:36",
    },
    {
      status: "3rd Arrival",
      time: "-",
      elapsed: "-",
    },
    {
      status: "Stop",
      time: "-",
      elapsed: "-",
    },
    {
      status: "Closed",
      time: "21 June 2021 02:33:22",
      elapsed: "02:15:17",
    },
  ],

  // Responses
  responsesHeading: "Responses",
  alarmHeading: "Alarm Level",
  callsignHeading: "CallSign",
  dispatchedHeading: "Dispatched",
  respondedHeading: "Responded",
  arrivedHeading: "Arrived",
  departedHeading: "Departed",
  responses: [
    {
      alarmLevel: 1,
      callsign: "HAST561",
      dispatched: "21 June 2021 00:18:22",
      responded: "21 June 2021 00:20:22",
      arrived: "21 June 2021 00:28:22",
      departed: "21 June 2021 01:18:22",
    },
    {
      alarmLevel: 1,
      callsign: "HAST567",
      dispatched: "21 June 2021 00:18:22",
      responded: "21 June 2021 00:20:22",
      arrived: "21 June 2021 00:28:22",
      departed: "21 June 2021 01:18:22",
    },
  ],

  // Cover Moves
  coverMovesHeading: "Cover Moves",
  coverCallsignHeading: "CallSign",
  startTimeHeading: "Start Time",
  endTimeHeading: "End Time",
  coverMoves: [
    {
      callsign: "HAST567",
      start: "18:27",
      end: "20:20",
    },
    {
      callsign: "HAST561",
      start: "18:37",
      end: "20:56",
    },
  ],

  // Notifications
  notificationsHeading: "Notifications",
  dateHeading: "Time",
  partyHeading: "Party Notified",
  notifications: [
    {
      status: "new",
      date: "18:27",
      party: "RFQ Tim Reynolds ACK page put thru HAST 567",
    },
    {
      status: "old",
      date: "18:41",
      party: "RFQ HAST FLAXMERE PAGES",
    },
  ],

  // Messages
  messagesHeading: "Messages",
  filterMessage: "Privacy filter has been applied",
  messagesTimeHeading: "Time",
  messageHeading: "Message",
  messages: [
    {
      status: "new",
      time: "21:00",
      message: "INC INFO: ALARM ACTIVATION - COMMERCIAL",
    },
    {
      status: "new",
      time: "20:50",
      message: "LOI search completed at 02/06/2021 00:19:00",
    },
    {
      status: "old",
      time: "20:02",
      message: "Recommended unit HAST561 for requirement PUMP (>3.0km)",
    },
    {
      status: "old",
      time: "19:36",
      message: "Recommended unit",
    },
    {
      status: "old",
      time: "19:28",
      message: "HAST567 for requirement PUMP (>3.1km)",
    },
    {
      status: "old",
      time: "19:19",
      message: "PA Selcal used to initiate alerting.",
    },
    {
      status: "old",
      time: "19:11",
      message: "Alerting completed HAST 561",
    },
  ],

  // Footer
  footerDownloadLabel: "Open as PDF",
  footerBackToTopLabel: "Back to top",
};

export default reportData;
